import { Controller } from "@hotwired/stimulus";
import { appendQueryParamToLink } from "../appQueryParamToLink";

export default class extends Controller {
  connect() {
    this.link = document.getElementById("link");
    const link = this.link;
    const fillInWineBottle = this.fillInWineBottle;
    /* TASTE PROFILE */
    $("#wineSliderDryness").slider({
      orientation: "vertical",
      range: "max",
      min: 0,
      max: 3,
      value: 3,
      step: 1,
      change: function (event, ui) {
        const slideValue = ui.value;
        const wineBottle = "#Wine_Bottle_Dryness";
        switch (slideValue) {
          case 2:
            fillInWineBottle(wineBottle, {
              top: 1,
              bottom: 0.4,
              middle: 0.4,
            });
            appendQueryParamToLink(link, "dryness=dry");
            break;
          case 1:
            fillInWineBottle(wineBottle, {
              top: 1,
              middle: 1,
              bottom: 0.4,
            });
            appendQueryParamToLink(link, "dryness=medium");
            break;
          case 0:
            fillInWineBottle(wineBottle, { top: 1, middle: 1, bottom: 1 });
            appendQueryParamToLink(link, "dryness=sweet");
            break;
          default:
            fillInWineBottle(wineBottle, {
              top: 0.4,
              bottom: 0.4,
              middle: 0.4,
            });
            break;
        }
      },
    });

    $("#wineSliderBody").slider({
      orientation: "vertical",
      range: "max",
      min: 0,
      max: 3,
      value: 3,
      step: 1,
      change: function (event, ui) {
        const slideValue = ui.value;
        const wineBottle = "#Wine_Bottle_Body";
        switch (slideValue) {
          case 2:
            fillInWineBottle(wineBottle, { top: 1, middle: 0.4, bottom: 0.4 });
            appendQueryParamToLink(link, "body=light");
            break;
          case 1:
            fillInWineBottle(wineBottle, { top: 1, middle: 1, bottom: 0.4 });
            appendQueryParamToLink(link, "body=medium");
            break;
          case 0:
            fillInWineBottle(wineBottle, { top: 1, middle: 1, bottom: 1 });
            appendQueryParamToLink(link, "body=full");
            break;
          default:
            fillInWineBottle(wineBottle, {
              top: 0.4,
              middle: 0.4,
              bottom: 0.4,
            });
            break;
        }
      },
    });

    $("#wineSliderTannin").slider({
      orientation: "vertical",
      range: "max",
      min: 0,
      max: 3,
      value: 3,
      step: 1,
      change: function (event, ui) {
        const slideValue = ui.value;
        const wineBottle = "#Wine_Bottle_Tannin";
        switch (slideValue) {
          case 2:
            fillInWineBottle(wineBottle, { top: 1, middle: 0.4, bottom: 0.4 });
            appendQueryParamToLink(link, "tannin=low");
            break;
          case 1:
            fillInWineBottle(wineBottle, { top: 1, middle: 1, bottom: 0.4 });
            appendQueryParamToLink(link, "tannin=medium");
            break;
          case 0:
            fillInWineBottle(wineBottle, { top: 1, middle: 1, bottom: 1 });
            appendQueryParamToLink(link, "tannin=heavy");
            break;
          default:
            fillInWineBottle(wineBottle, {
              top: 0.4,
              middle: 0.4,
              bottom: 0.4,
            });
            break;
        }
      },
    });

    /* MODALS */
    $(".dialogBox").dialog({ autoOpen: false, resizable: false, width: 700 });

    $(".dialogBox .close-button").on("click", function () {
      $(".dialogBox").dialog("close");
    });

    $(document).on("click", ".tannin-title", function (event) {
      $("#info-popup").dialog("open");
    });
  }

  adjustSliderPosition(wineSlider, values) {
    const { height, bottom } = values;
    $(`${wineSlider} .ui-widget-header`).attr("style", `height: ${height}`);
    $(`${wineSlider} .ui-slider-handle`).attr("style", `bottom: ${bottom}`);
  }

  fillInWineBottle(wineBottle, opacity) {
    const { top, middle, bottom } = opacity;
    $(`${wineBottle} #Top`).css({ opacity: top });
    $(`${wineBottle} #Middle`).css({ opacity: middle });
    $(`${wineBottle} #Bottom`).css({ opacity: bottom });
  }

  get flavorCharacteristics() {
    return {
      dryness: {
        flexible: "flexible",
        light: "dry",
        medium: "medium",
        strong: "sweet",
      },
      body: {
        flexible: "flexible",
        light: "light",
        medium: "medium",
        strong: "full",
      },
      tannin: {
        flexible: "flexible",
        light: "low",
        medium: "medium",
        strong: "heavy",
      },
    };
  }

  assignFlavor(event) {
    const { characteristic, characteristicFlavor, wineBottle, wineSlider } =
      event.params;
    const flavorCharacteristics = this.flavorCharacteristics[characteristic];
    console.log(event.params);
    console.log(flavorCharacteristics);
    switch (characteristicFlavor) {
      case flavorCharacteristics.flexible:
        this.fillInWineBottle(`${wineBottle}`, {
          top: 0.4,
          bottom: 0.4,
          middle: 0.4,
        });
        this.adjustSliderPosition(`${wineSlider}`, {
          height: "0%",
          bottom: "100%",
        });
        break;
      case flavorCharacteristics.light:
        this.fillInWineBottle(`${wineBottle}`, {
          top: 1,
          bottom: 0.4,
          middle: 0.4,
        });
        this.adjustSliderPosition(`${wineSlider}`, {
          height: "33.3333%",
          bottom: "66.6667%",
        });
        break;
      case flavorCharacteristics.medium:
        this.fillInWineBottle(`${wineBottle}`, {
          top: 1,
          middle: 1,
          bottom: 0.4,
        });
        this.adjustSliderPosition(`${wineSlider}`, {
          height: "66.6667%",
          bottom: "33.3333%",
        });
        break;
      case flavorCharacteristics.strong:
        this.fillInWineBottle(`${wineBottle}`, {
          top: 1,
          bottom: 1,
          middle: 1,
        });
        this.adjustSliderPosition(`${wineSlider}`, {
          height: "100%",
          bottom: "0%",
        });
        break;
      default:
        break;
    }
    appendQueryParamToLink(
      this.link,
      `${characteristic}=${characteristicFlavor}`
    );
  }
}
